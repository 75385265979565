/* global BigInt */
import React from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Grid from '@material-ui/core/Grid';
import {useNavigate, useParams} from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import extjs from '../ic/extjs.js';
import {useTheme} from '@material-ui/core/styles';
import NFT from './NFT';
import UserDetail from './UserDetail';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import getNri from '../ic/nftv.js';
import FilterListIcon from '@material-ui/icons/FilterList';
import CachedIcon from '@material-ui/icons/Cached';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import {makeStyles} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import {getExtCanisterId} from '../typescript/data/canisters/canister-details/wrapped-canister-id';
import {
    createEntrepotApiWithIdentity,
    defaultEntrepotApi,
    createCloudFunctionsEndpointUrl,
} from '../typescript/api/entrepot-apis/entrepot-data-api';
import {decodeNftId} from '../typescript/data/nft/nft-id';
import {treasureCanisterId} from '../typescript/data/canisters/treasure-canister';

import offerBlacklist from './../offer-blacklist.json';

const api = extjs.connect('https://icp0.io/');
const perPage = 60;

function useInterval(callback, delay) {
    const savedCallback = React.useRef();

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
var canUpdateNfts = true;
const useStyles = makeStyles(theme => ({
    tabsViewBig: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    tabsViewSmall: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    listingsView: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiGrid-container.MuiGrid-spacing-xs-2': {
                gridTemplateColumns: 'repeat(auto-fill, 50%)!important',
            },
        },
    },
    hideDesktop: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-flex',
        },
    },
    topUi: {
        '& .MuiFormControl-root': {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            minWidth: '150px',
        },
    },
    tabsViewTab: {
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            '&>span>span>svg': {
                display: 'none',
            },
            '&>span>span': {
                padding: '0 5px!important',
            },
        },
    },
    filtersViewOpen: {
        position: 'sticky',
        top: 72,
        width: 330,
        height: 'calc(100vh - 72px)',
        borderRight: '1px solid #aaa',
        overflowY: 'scroll',
        overflowX: 'hidden',
        paddingBottom: 50,
        [theme.breakpoints.down('xs')]: {
            //display:"none",
            position: 'fixed',
            backgroundColor: 'white',
            zIndex: 100,
            left: 0,
            right: 0,
            bottom: 0,
            width: '80%',
        },
    },
    filtersViewClosed: {
        position: 'sticky',
        top: 72,
        width: 60,
        height: 'calc(100vh - 72px)',
        borderRight: '1px solid #aaa',
        overflowY: 'hidden',
        overflowX: 'hidden',
        paddingBottom: 50,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
}));
export default function UserCollection(props) {
    const params = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const [
        displayedResults,
        setDisplayedResults,
    ] = React.useState([]);
    const [
        results,
        setResults,
    ] = React.useState(false);

    const getCollection = c => {
        if (typeof props.collections.find(e => e.canister === c) == 'undefined') return {};
        return props.collections.find(e => e.canister === c);
    };
    const [
        nfts,
        setNfts,
    ] = React.useState([]);
    const [
        whitelistedPawnCanisters,
        setWhitelistedPawnCanisters,
    ] = React.useState(false);
    const [
        tokenCanisters,
        setTokenCanisters,
    ] = React.useState([]);
    const [
        displayNfts,
        setDisplayNfts,
    ] = React.useState(false);
    const [
        collectionFilter,
        setCollectionFilter,
    ] = React.useState('all');
    const [
        page,
        setPage,
    ] = React.useState(1);
    var myPage = params?.address ? false : true;
    const [
        address,
        setAddress,
    ] = React.useState(params?.address ?? props.account.address ?? '');
    const [
        sort,
        setSort,
    ] = React.useState('mint_number');
    const [
        listingPrices,
        setListingPrices,
    ] = React.useState([]);
    const [
        toggleFilter,
        setToggleFilter,
    ] = React.useState(
        window.innerWidth < 600 ? false : JSON.parse(localStorage.getItem('_toggleFilter')) ?? true,
    );
    const [
        hideCollectionFilter,
        setHideCollectionFilter,
    ] = React.useState(true);
    const [
        gridSize,
        setGridSize,
    ] = React.useState(localStorage.getItem('_gridSize') ?? 'small');
    const [
        earnCollections,
        setEarnCollections,
    ] = React.useState(props.collections.filter(a => a.earn == true).map(a => a.id));
    var hiddenNfts = [];
    const changeGrid = (e, a) => {
        localStorage.setItem('_gridSize', a);
        setGridSize(a);
    };
    const changeToggleFilter = () => {
        localStorage.setItem('_toggleFilter', !toggleFilter);
        setToggleFilter(!toggleFilter);
    };
    const changeSort = event => {
        setPage(1);
        setSort(event.target.value);
    };

    const filterBefore = r => {
        var rf = r;
        return rf;
    };
    const filterAfter = r => {
        var rf = r;
        if (collectionFilter != 'all') rf = rf.filter(a => a.canister == collectionFilter);
        return rf;
    };
    const tabLink = p => {
        return myPage ? p : '/' + address + p;
    };

    const hideNft = async token => {
        hiddenNfts.push(token);
    };
    const refresh = async v => {
        if (v) {
            navigate(v);
            return;
        }
        if (!address) return;
        var data = [];
        const allUserDataEndpoint = createCloudFunctionsEndpointUrl([
            'user',
            address,
            'all',
        ]);

        // eslint-disable-next-line default-case
        switch (props.view) {
            case 'collected':
                //TODO
                var response = await axios(allUserDataEndpoint);
                data = response.data;
                data = data.map(a => ({...a, token: a.id}));
                break;
            case 'new-request':
                //if (whitelistedPawnCanisters === false) return;
                var response = await axios(allUserDataEndpoint);
                data = response.data;
                data = data.filter(a => !a.price && earnCollections.indexOf(a.canister) >= 0);
                data = data.map(a => ({...a, token: a.id}));
                break;
            case 'earn-nfts':
                var response = await axios(
                    createCloudFunctionsEndpointUrl([
                        'user',
                        address,
                        treasureCanisterId,
                        'all',
                    ]),
                );
                data = response.data;
                data = data.map(a => ({...a, token: a.id}));
                break;
            case 'selling':
                var response = await axios(
                    createCloudFunctionsEndpointUrl([
                        'user',
                        address,
                        'listed',
                    ]),
                );
                data = response.data.filter(a => a.price > 0);
                data = data.map(a => ({...a, token: a.id}));
                break;
            case 'favorites':
                var r = await createEntrepotApiWithIdentity(props.identity)
                    .canister('6z5wo-yqaaa-aaaah-qcsfa-cai')
                    .liked();
                data = offeredResponse.filter((a, i) => offeredResponse.indexOf(a) == i);
                data = data.map(a => ({
                    id: a,
                    token: a,
                    price: 0,
                    time: 0,
                    owner: false,
                    canister: decodeNftId(a).canister,
                    listing: null,
                }));
                break;
            case 'offers-made':
                const offeredResponse = await createEntrepotApiWithIdentity(props.identity)
                    .canister('fcwhh-piaaa-aaaak-qazba-cai')
                    .offered();
                data = offeredResponse.filter((a, i) => offeredResponse.indexOf(a) == i);
                data = data
                    .map(entry => ({
                        id: entry,
                        token: entry,
                        price: 0,
                        time: 0,
                        owner: false,
                        canister: decodeNftId(entry).canister,
                    }))
                    .filter(collection => !offerBlacklist.includes(collection.canister));
                break;
            case 'offers-received':
                var r = await Promise.all(
                    [
                        axios(allUserDataEndpoint),
                        createEntrepotApiWithIdentity(props.identity)
                            .canister('fcwhh-piaaa-aaaak-qazba-cai')
                            .allOffers(),
                    ].map(p => p.catch(e => e)),
                );
                var r2 = offeredResponse.filter(result => !(result instanceof Error));
                var r3 = r2[0].data
                    .map(a => ({...a, token: a.id}))
                    .filter(a => r2[1].indexOf(a.token) >= 0)
                    .filter(collection => !offerBlacklist.includes(collection.canister));
                data = r3;
                break;
        }
        data = data.map(a =>
            a.hasOwnProperty('price') &&
            a.hasOwnProperty('time') &&
            !a.hasOwnProperty('listing') &&
            a.price > 0
                ? {
                      ...a,
                      listing: {price: BigInt(a.price), locked: a.time > 0 ? [BigInt(a.time)] : []},
                  }
                : a,
        );
        hiddenNfts = hiddenNfts.filter(x => data.map(a => a.id).includes(x));
        data = data.filter(a => hiddenNfts.indexOf(a.id) < 0);
        data = filterBefore(data);
        setTokenCanisters(data.map(d => d.canister));
        setResults(data);
    };

    const theme = useTheme();
    const styles = {
        empty: {
            maxWidth: 1200,
            margin: '0 auto',
            textAlign: 'center',
        },
        grid: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
    };
    useInterval(refresh, 10 * 60 * 1000);

    const updateNfts = (l, s, cf) => {
        if (canUpdateNfts) {
            canUpdateNfts = false;
            var _nfts = l ?? nfts;
            var _sort = s ?? sort;
            var _collectionFilter = cf ?? collectionFilter;
            if (!_nfts) return;
            if (l) setNfts(l);
            var _displayNfts = _nfts;
            _displayNfts = _displayNfts.filter(
                (token, i) =>
                    _collectionFilter == 'all' ||
                    getExtCanisterId(decodeNftId(token).canister) == _collectionFilter,
            );
            _displayNfts = _displayNfts.sort((a, b) => {
                switch (sort) {
                    case 'price_asc':
                        var ap = a.price === 0 ? false : a.price;
                        var bp = b.price === 0 ? false : b.price;
                        if (ap === false && bp === false) return 0;
                        if (ap === false) return -1;
                        if (bp === false) return 1;
                        return ap - bp;
                    case 'price_desc':
                        var ap = a.price === 0 ? false : a.price;
                        var bp = b.price === 0 ? false : b.price;
                        if (ap === false && bp === false) return 0;
                        if (ap === false) return 1;
                        if (bp === false) return -1;
                        return bp - ap;
                    case 'mint_number':
                        return decodeNftId(a).index - decodeNftId(b).index;
                    case 'nri':
                        var aa = decodeNftId(a);
                        var bb = decodeNftId(b);
                        var nriA = getNri(aa.canister, aa.index);
                        var nriB = getNri(bb.canister, bb.index);
                        if (nriA === false && nriB === false) return 0;
                        if (nriA === false) return 1;
                        if (nriB === false) return -1;
                        return Number(nriB) - Number(nriA);
                    default:
                        return 0;
                }
            });
            setDisplayNfts(_displayNfts);
            setHideCollectionFilter(false);
            setTokenCanisters(
                _nfts.map(tokenid => getExtCanisterId(decodeNftId(tokenid).canister)),
            );
            canUpdateNfts = true;
        }
    };

    useInterval(refresh, 10 * 60 * 1000);
    React.useEffect(() => {
        setPage(1);
        //if (displayedResults) setDisplayedResults(false);
        //else refresh();
    }, [sort]);
    React.useEffect(() => {
        setPage(1);
        if (displayedResults) setDisplayedResults(false);
        else refresh();
    }, [collectionFilter]);
    React.useEffect(() => {
        if (displayedResults === false) refresh();
    }, [displayedResults]);
    React.useEffect(() => {
        setHideCollectionFilter(false);
        if (results.length) setDisplayedResults(filterAfter(results));
        else setDisplayedResults([]);
    }, [results]);
    React.useEffect(() => {
        setDisplayedResults(false);
        defaultEntrepotApi
            .canister(treasureCanisterId)
            .tp_whitelisted()
            .then(r => {
                setWhitelistedPawnCanisters(r);
            });
    }, []);

    React.useEffect(() => {
        if (address) {
            setHideCollectionFilter(true);
            if (collectionFilter != 'all') setCollectionFilter('all');
            else {
                if (displayedResults) setDisplayedResults(false);
                else refresh();
            }
        }
    }, [
        address,
        props.view,
        whitelistedPawnCanisters,
    ]);
    React.useEffect(() => {
        if (myPage) setAddress(props.account.address);
    }, [props.account.address]);

    return (
        <div style={{minHeight: 'calc(100vh - 221px)', marginBottom: -75}}>
            <UserDetail
                view={props.view}
                navigate={v => navigate(tabLink(v))}
                classes={classes}
                address={address}
                title={myPage ? 'My Collection' : ''}
            />
            <div
                id="mainNfts"
                style={{
                    position: 'relative',
                    marginLeft: -24,
                    marginRight: -24,
                    marginBottom: -24,
                    borderTop: '1px solid #aaa',
                    borderBottom: '1px solid #aaa',
                    display: 'flex',
                }}
            >
                <div className={toggleFilter ? classes.filtersViewOpen : classes.filtersViewClosed}>
                    <List>
                        <ListItem style={{paddingRight: 0}} button onClick={changeToggleFilter}>
                            <ListItemIcon style={{minWidth: 40}}>
                                <FilterListIcon />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{noWrap: true}}
                                secondaryTypographyProps={{noWrap: true}}
                                primary={<strong>Collections</strong>}
                            />
                            <ListItemIcon>
                                {toggleFilter ? <CloseIcon fontSize={'large'} /> : ''}
                            </ListItemIcon>
                        </ListItem>
                        {toggleFilter && (tokenCanisters.length > 0 || hideCollectionFilter) ? (
                            <>
                                {hideCollectionFilter ? (
                                    <ListItem>
                                        <ListItemText>
                                            <strong>Loading...</strong>
                                        </ListItemText>
                                    </ListItem>
                                ) : (
                                    <>
                                        <ListItem
                                            selected={collectionFilter === 'all'}
                                            button
                                            onClick={() => {
                                                setCollectionFilter('all');
                                            }}
                                        >
                                            <ListItemText>
                                                <strong>View All Collections</strong>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={tokenCanisters.length}
                                                    variant="outlined"
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {tokenCanisters
                                            .filter(
                                                (a, i) =>
                                                    tokenCanisters.indexOf(a) == i &&
                                                    typeof getCollection(a) != 'undefined',
                                            ) //filter unique
                                            .map(canister => {
                                                var _collection = getCollection(canister);
                                                return (
                                                    <ListItem
                                                        key={canister}
                                                        selected={collectionFilter === canister}
                                                        button
                                                        onClick={() => {
                                                            setCollectionFilter(canister);
                                                        }}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <img
                                                                    alt={_collection.name}
                                                                    src={
                                                                        _collection.avatar ??
                                                                        '/collections/' +
                                                                            canister +
                                                                            '.jpg'
                                                                    }
                                                                    style={{height: 64}}
                                                                />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText>
                                                            {_collection.name}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <Chip
                                                                label={
                                                                    tokenCanisters.filter(
                                                                        a => a === canister,
                                                                    ).length
                                                                }
                                                                variant="outlined"
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                    </>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </List>
                </div>
                <div
                    className={classes.listingsView}
                    style={{flexGrow: 1, padding: '10px 16px 50px 16px'}}
                >
                    <div style={{}}>
                        <Grid className={classes.topUi} container style={{minHeight: 66}}>
                            <Grid item xs={12} sm={'auto'} style={{marginBottom: 10}}>
                                <ToggleButtonGroup
                                    className={classes.hideDesktop}
                                    style={{marginTop: 5, marginRight: 10}}
                                    size="small"
                                >
                                    <ToggleButton value="" onClick={changeToggleFilter}>
                                        <FilterListIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    style={{marginTop: 5, marginRight: 10}}
                                    size="small"
                                >
                                    <ToggleButton
                                        value=""
                                        onClick={() => setDisplayedResults(false)}
                                    >
                                        <CachedIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    style={{marginTop: 5, marginRight: 20}}
                                    size="small"
                                    value={gridSize}
                                    exclusive
                                    onChange={changeGrid}
                                >
                                    <ToggleButton value={'small'}>
                                        <ViewModuleIcon />
                                    </ToggleButton>
                                    <ToggleButton value={'large'}>
                                        <ViewComfyIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} sm={'auto'}>
                                <FormControl style={{marginRight: 20}}>
                                    <InputLabel>Sort by</InputLabel>
                                    <Select value={sort} onChange={changeSort}>
                                        <MenuItem value={'mint_number'}>Minting #</MenuItem>
                                        <MenuItem value={'nri'}>NFT Rarity Index</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {displayedResults && displayedResults.length > perPage ? (
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <Pagination
                                        className={classes.pagination}
                                        size="small"
                                        count={Math.ceil(displayedResults.length / perPage)}
                                        page={page}
                                        onChange={(e, v) => setPage(v)}
                                    />
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </div>
                    <div style={{minHeight: 500}}>
                        <div style={{}}>
                            {displayedResults === false ? (
                                <>
                                    <Typography paragraph style={{fontWeight: 'bold'}} align="left">
                                        Loading...
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    {displayedResults.length === 0 ? (
                                        <Typography
                                            paragraph
                                            style={{fontWeight: 'bold'}}
                                            align="left"
                                        >
                                            We found no results
                                        </Typography>
                                    ) : (
                                        <Typography
                                            paragraph
                                            style={{fontWeight: 'bold'}}
                                            align="left"
                                        >
                                            {displayedResults.length} items
                                        </Typography>
                                    )}
                                </>
                            )}
                        </div>
                        {displayedResults && displayedResults.length ? (
                            <div>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    alignItems="stretch"
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns:
                                            gridSize === 'small'
                                                ? 'repeat(auto-fill, 300px)'
                                                : 'repeat(auto-fill, 200px)',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {displayedResults
                                        .sort((a, b) => {
                                            switch (sort) {
                                                case 'price_asc':
                                                    var ap = a.price === 0 ? false : a.price;
                                                    var bp = b.price === 0 ? false : b.price;
                                                    if (ap === false && bp === false) return 0;
                                                    if (ap === false) return -1;
                                                    if (bp === false) return 1;
                                                    return ap - bp;
                                                case 'price_desc':
                                                    var ap = a.price === 0 ? false : a.price;
                                                    var bp = b.price === 0 ? false : b.price;
                                                    if (ap === false && bp === false) return 0;
                                                    if (ap === false) return 1;
                                                    if (bp === false) return -1;
                                                    return bp - ap;
                                                case 'mint_number':
                                                    return (
                                                        decodeNftId(a.token).index -
                                                        decodeNftId(b.token).index
                                                    );
                                                case 'nri':
                                                    var aa = decodeNftId(a.token);
                                                    var bb = decodeNftId(b.token);
                                                    var nriA = getNri(aa.canister, aa.index);
                                                    var nriB = getNri(bb.canister, bb.index);
                                                    if (nriA === false && nriB === false) return 0;
                                                    if (nriA === false) return 1;
                                                    if (nriB === false) return -1;
                                                    return Number(nriB) - Number(nriA);
                                                default:
                                                    return 0;
                                            }
                                        })
                                        .filter(
                                            (token, i) =>
                                                i >= (page - 1) * perPage && i < page * perPage,
                                        )
                                        .map((token, i) => {
                                            return (
                                                <NFT
                                                    collections={props.collections}
                                                    gridSize={gridSize}
                                                    view={props.view}
                                                    //faveRefresher={(props.view == 'favorites' ? updateFavorites : false)}
                                                    loggedIn={props.loggedIn}
                                                    identity={props.identity}
                                                    tokenid={token.token}
                                                    hideNft={hideNft}
                                                    key={token.token}
                                                    listing={token?.listing}
                                                    unpackNft={props.unpackNft}
                                                    listNft={props.listNft}
                                                    cancelNft={props.cancelNft}
                                                    wrapAndListNft={props.wrapAndListNft}
                                                    unwrapNft={props.unwrapNft}
                                                    transferNft={props.transferNft}
                                                    pawnNft={props.pawnNft}
                                                    loader={props.loader}
                                                    refresh={refresh}
                                                />
                                            );
                                        })}
                                </Grid>
                            </div>
                        ) : (
                            ''
                        )}
                        {displayedResults && displayedResults.length > perPage ? (
                            <Pagination
                                className={classes.pagination}
                                size="small"
                                count={Math.ceil(displayedResults.length / perPage)}
                                page={page}
                                onChange={(e, v) => setPage(v)}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
